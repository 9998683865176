import React from 'react'
import { array, node, string } from 'prop-types'

import SiteContainer from '@/component/Landmark/SiteContainer'
import NavigationProgress from '@/component/Landmark/NavigationProgress'
import Section from '@/component/Primitive/Section'
import Main from '@/component/Landmark/Main'
import { FooterPropTypes } from '../Footer'

const SiteWrapper = ({
  background,
  footerProps,
  children,
  closeUrl,
  pathname,
  navItems,
  title
}) => {
  return (
    <SiteContainer
      title={title}
      background={background}
      closeUrl={closeUrl}
      footerProps={footerProps}
    >
      {navItems.length > 0 && (
        <Section id="navigation" backgroundLight borderBottom shadowBottom>
          <NavigationProgress pathname={pathname} routes={navItems} />
        </Section>
      )}
      <Main>{children}</Main>
    </SiteContainer>
  )
}

SiteWrapper.defaultProps = {
  navItems: []
}

SiteWrapper.propTypes = {
  background: string,
  children: node,
  closeUrl: string,
  pathname: string,
  navItems: array,
  title: string,
  footerProps: FooterPropTypes
}

export default SiteWrapper
