import React from 'react'
import { arrayOf, number, shape, string } from 'prop-types'

import StepProgress from '../../Primitive/StepProgress'

const NavigationProgress = ({ pathname, routes }) => {
  const currentStep = pathname
    ? routes.findIndex((route) => {
        if (route.alias && route.alias.includes(pathname)) return true
        return route.path === pathname
      })
    : 0
  const enableNavigation = currentStep < routes.length
  return (
    <StepProgress label="Progress">
      {routes.map((route, i) => (
        <StepProgress.Item
          key={`StepProgressItem:${route.path}`}
          {...(enableNavigation && { to: route.path })}
          icon={route.icon}
          current={currentStep === i}
          complete={currentStep > i}
          {...(route.progress >= 0 && { progress: route.progress })}
        >
          {route.text}
        </StepProgress.Item>
      ))}
    </StepProgress>
  )
}

NavigationProgress.defaultProps = {
  routes: []
}

NavigationProgress.propTypes = {
  pathname: string,
  routes: arrayOf(
    shape({
      icon: string,
      path: string,
      progress: number,
      text: string
    })
  )
}

export default NavigationProgress
