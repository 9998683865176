import React from 'react'
import { bool, func, node, number, string } from 'prop-types'
import classNames from 'classnames'

import styles from './StepProgressItem.module.scss'

import Icon from '../../../Icon'
import SmartLink from '../../../SmartLink'
import VisuallyHidden from '../../../VisuallyHidden'

const StepProgressItem = ({
  children,
  complete,
  current,
  href,
  icon,
  onClick,
  progress,
  to
}) => {
  const isClickable = (complete || current) && (href || to || onClick)
  const StepProgressItemEl = isClickable ? SmartLink : 'div'
  return (
    <li
      className={classNames(
        styles.StepProgressItem,
        complete && styles.complete,
        current && styles.current
      )}
      {...(current && { 'aria-current': 'step' })}
    >
      <StepProgressItemEl
        className={styles.StepProgressItemInner}
        {...(href && { href })}
        {...(onClick && { onClick })}
        {...(to && { to })}
      >
        {icon && <Icon type={icon} a11yText="" />}
        <div>{children}</div>
        {complete && <VisuallyHidden>Complete</VisuallyHidden>}
        <div className={styles.StepProgressItemSeparator}>
          <span {...(progress && { style: { width: `${progress * 100}%` } })} />
        </div>
      </StepProgressItemEl>
    </li>
  )
}

StepProgressItem.propTypes = {
  children: node.isRequired,
  complete: bool,
  current: bool,
  icon: string,
  progress: number,
  href: string,
  to: string,
  onClick: func
}

export default StepProgressItem
