import React from 'react'
import { oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './HeaderHero.module.scss'

import IconButton from '@/component/Primitive/IconButton'
import LogoLockup from '@/component/Primitive/LogoLockup'

const HeaderHero = ({ background, closeA11yLabel, closeUrl, title }) => (
  <header
    className={classNames(
      styles.HeaderHero,
      background && styles[`bg-${background}`]
    )}
  >
    <div className={styles.HeaderHeroBg} />

    <div className={styles.HeaderHeroInner}>
      <LogoLockup title={title} />
    </div>
    {closeUrl && (
      <div className={styles.HeaderHeroClose}>
        <IconButton
          icon="close"
          href={closeUrl}
          a11yText={closeA11yLabel}
          rounded
        />
      </div>
    )}
  </header>
)

HeaderHero.defaultProps = {
  closeA11yLabel: 'Return to the main Citygate website',
  closeUrl: 'https://www.citygate.co.uk'
}

HeaderHero.propTypes = {
  background: oneOf(['dashboard']),
  title: string
}

export default HeaderHero
