import React from 'react'
import { node, string } from 'prop-types'

import styles from './StepProgressWrapper.module.scss'

const StepProgressWrapper = ({ children, label }) => {
  return (
    <nav aria-label={label} className={styles.StepProgressWrapper}>
      <ol className={styles.StepProgressWrapperList}>{children}</ol>
    </nav>
  )
}

StepProgressWrapper.propTypes = {
  children: node.isRequired,
  label: string.isRequired
}

export default StepProgressWrapper
