import React from 'react'
import { node, string } from 'prop-types'

import styles from './SiteContainer.module.scss'

import A11yNavigation from '../../Primitive/A11yNavigation'
import HeaderHero from '../HeaderHero'
import Footer, { FooterPropTypes } from '../Footer'

const SiteContainer = ({
  background,
  children,
  closeUrl,
  title,
  footerProps
}) => (
  <div className={styles.SiteContainer}>
    <A11yNavigation>
      <a href="#content">Jump to main content</a>
      <a href="#navigation">Jump to primary navigation</a>
    </A11yNavigation>
    <div className={styles.SiteContainerInner}>
      <HeaderHero background={background} closeUrl={closeUrl} title={title} />
      <div className={styles.SiteContainerContent}>{children}</div>
      <Footer {...footerProps} />
    </div>
  </div>
)

SiteContainer.propTypes = {
  background: string,
  children: node.isRequired,
  closeUrl: string,
  title: string,
  footerProps: FooterPropTypes
}

export default SiteContainer
