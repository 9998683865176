import React from 'react'
import { shape, string } from 'prop-types'

import styles from './Footer.module.scss'

import Hide from '../../Primitive/Hide'
import Type from '../../Primitive/Type'

const Footer = ({ phoneNumber }) => (
  <footer className={styles.Footer}>
    <Type size="xs">
      Need assistance?{' '}
      <a href={phoneNumber?.href || 'tel:03300082220'}>
        Call us
        <Hide below="tablet" as="span">
          {' '}
          on {phoneNumber?.text || <>0330&nbsp;008&nbsp;2220</>}
        </Hide>
      </a>
    </Type>
  </footer>
)

export const FooterPropTypes = {
  phoneNumber: shape({
    text: string,
    href: string
  })
}

Footer.propTypes = {
  ...FooterPropTypes
}

export default Footer
